/**
 * Generates a schema.org structured content object from a blog author.
 *
 * @param author
 * @returns {{'@type': string, name}}
 */
const generatePersonSchemaDataFromAuthor = author => {
    const person = {
        '@type': 'Person',
        name: author.name,
    }

    if (author.linkedIn) {
        person.url = `https://www.linkedin.com/in/${author.linkedIn}/`
    }

    return person
}

export default generatePersonSchemaDataFromAuthor
