import React from 'react'
import SanityImage from 'gatsby-plugin-sanity-image'
import blocksToText from '../utilities/blocksToText'
import styled from 'styled-components'
import truncate from 'lodash/truncate'

/**
 * @param author
 * @returns {JSX.Element}
 * @constructor
 */
const Author = ({ author }) => {
    return (
        <AuthorStyles className='py-3 mt-4'>
            <div className='mb-3'>
                <SanityImage
                    // pass asset, hotspot, and crop fields
                    {...author.image}
                    alt={author.name}
                    // tell Sanity how large to make the image (does not set any CSS)
                    width={150}
                    className='author-image rounded-circle'
                />
            </div>
            <p className='h5'>{author.name}</p>
            <p className='text-secondary mb-4'>{truncate(blocksToText(author._rawBio, true), { length: 200 })}</p>

            {author.linkedIn && (
                <a
                    href={author.linkedIn}
                    className='btn btn-primary px-5 shadow'
                    target='_blank'
                    rel='noopener noreferrer'>
                    LinkedIn
                </a>
            )}
        </AuthorStyles>
    )
}

export default Author

const AuthorStyles = styled.div`
    .author-image {
        width: 150px;
        aspect-ratio: 1/1;
        object-fit: cover;
        @media (min-width: 992px) {
            width: 96px;
        }
    }
`
