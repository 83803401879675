import React, { useState } from 'react'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputGroup } from 'react-bootstrap'
import { useIntl } from 'react-intl'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const SearchField = ({ options = [] }) => {
    const intl = useIntl()

    const [value, setValue] = useState('')
    const [suggestions, setSuggestions] = useState(options)

    // Get suggestions based on input value
    const getSuggestions = value => {
        const inputValue = value.trim().toLowerCase()
        const inputLength = inputValue.length

        const suggestions =
            inputLength === 0 ? options : options.filter(article => article.title.toLowerCase().includes(inputValue))
        // Display only 5 first suggestions
        return suggestions.slice(0, 5)
    }

    const getSuggestionValue = suggestion => suggestion.title

    const renderSuggestion = (suggestion, { query, isHighlighted }) => {
        const suggestionText = suggestion.title
        const matches = match(suggestionText, query)
        const parts = parse(suggestionText, matches)
        return (
            <div className={`suggestion px-3 py-2 ${isHighlighted ? 'bg-light' : ''}`}>
                {parts.map((part, index) =>
                    part.highlight ? (
                        <mark className='px-0' key={index}>
                            {part.text}
                        </mark>
                    ) : (
                        <span key={index}>{part.text}</span>
                    )
                )}
            </div>
        )
    }

    const renderInputComponent = inputProps => (
        <InputGroup>
            <InputGroup.Text className='bg-transparent'>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
            </InputGroup.Text>
            <input {...inputProps} />
        </InputGroup>
    )

    const inputProps = {
        className: 'form-control border-start-0',
        placeholder: intl.formatMessage({ id: 'search' }),
        value,
        onChange: (e, { newValue }) => setValue(newValue),
    }

    return (
        <SearchFieldStyles>
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={({ value }) => setSuggestions(getSuggestions(value))}
                onSuggestionsClearRequested={() => setSuggestions([])}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                renderInputComponent={renderInputComponent}
                inputProps={inputProps}
                shouldRenderSuggestions={() => true}
                onSuggestionSelected={(props, { suggestion }) => {
                    // On selection, clear out the field and navigate to selected article
                    setValue('')
                    navigate(`/${suggestion.lang}/${suggestion.slug.current}`)
                }}
            />
        </SearchFieldStyles>
    )
}

export default SearchField

const SearchFieldStyles = styled.div`
    .react-autosuggest__container {
        position: relative;
    }

    .react-autosuggest__suggestions-container--open {
        position: absolute;
        border: 1px solid #aaa;
        background-color: #fff;
        z-index: 2;
        width: 100%;

        .suggestion:hover {
            background-color: var(--bs-light);
        }

        ul {
            list-style-type: none;
            padding: 0.5rem 0;
            margin-bottom: 0;

            li {
                cursor: pointer;
            }
        }
    }
`
