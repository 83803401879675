import React from 'react'
import urlForSanityImage from '../utilities/urlForSanityImage'
import { Link } from 'gatsby'

/**
 * @type {{types: {image: (function({value: *}))}, block: {blockquote: (function({children: *}))}}}
 */
const blogPostPortableTextComponents = {
    types: {
        imageWithCaptionAndAlt: ({ value }) => {
            const source = urlForSanityImage(value.image.asset._ref)
                .fit('max')
                .auto('format')
                .width(1320)
                .quality(90)
                .toString()

            const alt = value.alt || ''

            return (
                <figure>
                    <img src={source} alt={alt} className='w-100 mt-4 mb-4' />
                    {value.caption && (
                        <figcaption className='text-muted fst-italic fw-light small mt-n3 mb-4'>
                            {value.caption}
                        </figcaption>
                    )}
                </figure>
            )
        },
    },
    block: {
        blockquote: ({ children }) => (
            <blockquote className='blockquote border-start border-light border-5 ps-3 py-1'>{children}</blockquote>
        ),
    },
    marks: {
        link: ({ children, value }) => {
            if (value.href.startsWith('/')) {
                return <Link to={value.href}>{children}</Link>
            } else {
                return (
                    <a href={value.href} rel='noreferrer noopener' target='_blank'>
                        {children}
                    </a>
                )
            }
        },
    },
}

export default blogPostPortableTextComponents
