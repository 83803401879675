import React from 'react'
import { graphql } from 'gatsby'
import SEOTools from '../components/SEOTools'
import { PortableText } from '@portabletext/react'
import { Col, Container, Row } from 'react-bootstrap'
import blogPostPortableTextComponents from '../components/blogPostPortableTextComponents'
import blocksToText from '../utilities/blocksToText'
import truncate from 'lodash/truncate'
import Author from '../components/Author'
import PostListing from '../components/PostListing'
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl'
import generateArticleSchemaDataFromPost from '../utilities/generateArticleSchemaDataFromPost'
import { useLocation } from '@reach/router'
import ClientRendered from '../components/ClientRendered'
import styled from 'styled-components'
import SearchField from '../components/SearchField'
import SanityImage from 'gatsby-plugin-sanity-image'
import urlForSanityImage from '../utilities/urlForSanityImage'

/**
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
export default function BlogPostPage({ data }) {
    const location = useLocation()

    const { page, allSanityPost } = data

    const { mainImage } = page
    const metaDescription = truncate(blocksToText(page._rawBody, true), { length: 200 })
    const metaImage = mainImage.image ? urlForSanityImage(mainImage.image.asset._id).toString() : null
    const publishedAt = new Date(page.publishedAt)

    const articleStructuredData = generateArticleSchemaDataFromPost(page)

    return (
        <Container as='main'>
            <SEOTools title={page.title} image={metaImage} description={metaDescription} location={location}>
                <script type='application/ld+json'>{JSON.stringify(articleStructuredData)}</script>
            </SEOTools>
            <Row className='mt-4 text-secondary'>
                {/* Render publishedat time only on client, because server time at
                ssr might differ and cause hydration issues */}
                <Col lg={{ span: 8, order: 1 }} xs={{ order: 2 }} className='mt-3'>
                    <ClientRendered fallback={<>&nbsp;</>}>
                        <FadeInSpan>
                            <FormattedDate value={publishedAt} /> <FormattedTime value={publishedAt} />
                        </FadeInSpan>
                    </ClientRendered>
                </Col>
                <Col lg={{ span: 4, order: 2 }} xs={{ order: 1 }} className='mt-0 mt-lg-3'>
                    <SearchField options={allSanityPost.nodes} />
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col lg='7' as='article'>
                    <h1 className='mt-3 mt-lg-5 mb-4'>{page.title}</h1>
                    {page.secondaryTitle && (
                        <p className='mb-4 mt-n3 text-secondary h2 secondary-title fw-normal'>{page.secondaryTitle}</p>
                    )}

                    {mainImage.image && (
                        <figure>
                            <SanityImage
                                // pass asset, hotspot, and crop fields
                                {...mainImage.image}
                                alt={mainImage.alt}
                                // tell Sanity how large to make the image (does not set any CSS)
                                width={770}
                                sizes='(max-width: 385px) 385px,(max-width: 578px) 578px,770px'
                                loading='eager'
                                className='mt-4 mb-0 shadow-sm w-100'
                            />
                            {mainImage.caption && (
                                <figcaption className='text-muted fst-italic fw-light small mt-3 mb-n3'>
                                    {mainImage.caption}
                                </figcaption>
                            )}
                        </figure>
                    )}

                    <div className='mt-5'>
                        <PortableText value={page._rawBody} components={blogPostPortableTextComponents} />
                    </div>
                </Col>
                <Col lg={{ span: 4, offset: 1 }}>
                    <hr className='d-lg-none my-5' />
                    <Author author={page.author} />
                    {!!page.related?.length && (
                        <>
                            <hr />
                            <div className='py-3 mt-4'>
                                <p className='h5'>
                                    <FormattedMessage id='related' />
                                </p>
                                <PostListing related={page.related} />
                            </div>
                        </>
                    )}
                </Col>
            </Row>
        </Container>
    )
}

const FadeInSpan = styled.span`
    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    animation-name: fadein;
    animation-duration: 0.2s;
`

export const query = graphql`
    query ($slug: String!, $lang: String!) {
        page: sanityPost(slug: { current: { eq: $slug } }, lang: { eq: $lang }) {
            _rawBody
            publishedAt
            title
            secondaryTitle
            lang
            mainImage {
                alt
                caption
                image {
                    ...ImageWithPreview
                }
            }
            author {
                _rawBio
                image {
                    ...ImageWithPreview
                }
                name
                linkedIn
            }
            related {
                title
                _rawBody
                slug {
                    current
                }
                lang
                mainImage {
                    alt
                    image {
                        ...ImageWithPreview
                    }
                }
            }
        }
        allSanityPost(filter: { lang: { eq: $lang } }) {
            nodes {
                title
                lang
                slug {
                    current
                }
            }
        }
    }
`
