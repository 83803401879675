import imageUrlBuilder from '@sanity/image-url'

const builder = imageUrlBuilder({
    projectId: process.env.GATSBY_SANITY_CMS_PROJECT_ID,
    dataset: 'production',
})

const urlForSanityImage = source => builder.image(source)

export default urlForSanityImage
